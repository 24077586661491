import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, tokenAtom } from '../../../core/config/atoms';

import { ChevronDown } from 'lucide-react';
import { ScrollArea } from "@/components/ui/scroll-area";

import { motion } from "framer-motion";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { toast } from 'react-toastify';
import Moment from 'moment';
import * as XLSX from 'xlsx';

import ServicePack from 'services/servicePack';
import ServiceTransaction from 'services/serviceTransaction';

export default function PaneTransaction({ cid }) {

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);

    const [transactions, setTransactions] = useState([]);
    const [packs, setPacks] = useState([]);

    const [filter, setFilter] = useState({ 'userId': cid });

    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const packService = new ServicePack();
    const transactionService = new ServiceTransaction();

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const getExportItems = () => {
        let items = [];
        transactions.forEach((item) => {
            items.push({
                trnId: item.trnId,
                trnType: item.trnType,
                amount: item.amount,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "transactions");
        XLSX.writeFile(workbook, "transaction.xlsx");
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToCsv = () => {
        let headers = ['trnId,trnType,amount,status,createdAt'];
        let usersCsv = transactions.reduce((tran, item) => {
            const { trnId, trnType, amount, status, createdAt } = item
            tran.push([trnId, trnType, amount, status, createdAt].join(','))
            return tran
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'transaction.csv',
            fileType: 'text/csv',
        });
    }

    const getDetails = (code) => {
        let pack = packs.filter((a) => a.code == code)[0];
        return `${pack.title} (${code})`;
    }

    const getStsCss = (status) => {
        switch (status) {
            case 'prog':
                return " bg-yellow-100 text-yellow-800";
            case 'paid':
                return " bg-green-100 text-green-800";
            case 'fail':
            case 'cancel':
                return " bg-red-100 text-red-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const getStsTitle = (status) => {
        switch (status) {
            case 'prog':
                return "Processing";
            case 'paid':
                return "Paid";
            case 'fail':
                return "Failed";
            case 'cancel':
                return "Canceled";
            default:
                return "NA";
        }
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await transactionService.getAllTransaction(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setTransactions(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const loadData = () => {
        (async function () {
            var res = await packService.getAllPack({ 'country': userData.country }, token);
            if (res['status']) {
                const sortedData = res.data.slice().sort((a, b) => {
                    return a.index - b.index;
                });
                setPacks(sortedData);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }

            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await transactionService.getAllTransaction(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setTransactions(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const updateTransactions = (trans, status) => {
        if (status) {
            let bodyA = {
                "uid": cid,
                "tid": trans.trnId,
                "wkey": trans.walletId,
                "pid": trans.paymentData.pid,
                "status": "paid"
            };
            transactionService.updateTransaction(bodyA, token).then((res) => {
                if (res.status) {
                    toast.success('Payment successful!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                loadData();
            });
        } else {
            let bodyA = {
                "uid": cid,
                "tid": trans.trnId,
                "wkey": trans.walletId,
                "pid": trans.paymentData.pid,
                "status": "paid"
            };
            transactionService.updateTransaction(bodyA, token).then((res) => {
                if (res.status) {
                    toast.success('Payment successful!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                loadData();
            });
        }

    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, filter]);

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    return (
        <div className="col-span-12 mt-1 px-2">
            <div className="w-full shadow-md rounded-md border">
                <table className="min-w-full divide-y divide-gray-200 px-4">
                    <thead className="bg-gray-50">
                        <tr className=''>
                            <th scope="col" className="border-b border-gray-200" colSpan={6}>
                                <div className='w-full grid grid-cols-12 gap-2'>
                                    <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                        <div className='w-full flex col-span-12 items-center'>
                                            <div className='w-[80px]'>
                                                <p>Search :</p>
                                            </div>
                                            <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by transaction id" className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                        </div>
                                    </form>
                                    <div className='col-span-1 flex items-center'>
                                        <DropdownMenu>
                                            <DropdownMenuTrigger>
                                                <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent>
                                                <div
                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                    <div>
                                                        <ul className='flex flex-col items-start p-2'>
                                                            <DropdownMenuItem>
                                                                <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                    exportToExcel();
                                                                }}>
                                                                    <div className='flex items-center text-base'>
                                                                        <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                    </div>
                                                                </li>
                                                            </DropdownMenuItem>
                                                            <DropdownMenuItem>
                                                                <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                    exportToCsv();
                                                                }}>
                                                                    <div className='flex items-center text-base'>
                                                                        <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                    </div>
                                                                </li>
                                                            </DropdownMenuItem>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </DropdownMenuContent>
                                        </DropdownMenu>
                                    </div>
                                </div>
                            </th>
                        </tr>
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Transaction ID
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Package Details
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Amount
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Transaction Date
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Status
                            </th>
                            <th scope="col" className="px-2 py-3"></th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {
                            transactions.length !== 0 && transactions.map((tran) => {
                                return <tr className="hover:bg-gray-100" key={tran._id}>
                                    <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div className='flex items-center text-prime uppercase'>
                                                {tran.trnId}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div>{getDetails(tran.paymentData.pcode)}</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div>₹ {tran.amount}</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div>{Moment(tran.createdAt).format('DD, MMM yyyy - hh:mm:ss')}</div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getStsCss(tran.status)}`}>
                                            {getStsTitle(tran.status)}
                                        </span>
                                    </td>
                                    <td>
                                        {
                                            tran.status == 'prog' && <DropdownMenu>
                                                <DropdownMenuTrigger>
                                                    <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent>
                                                    <div className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-60px' }}>
                                                        <div>
                                                            <ul className='flex flex-col items-start p-2'>
                                                                {
                                                                    tran.status == 'prog' && <DropdownMenuItem>
                                                                        <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                            updateTransactions(tran, true);
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <i className="las la-chevron-circle-up text-lg"></i>
                                                                                <p className='text-sm font-serif ml-2'>Make Paid</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                }
                                                                {
                                                                    tran.status == 'prog' && <DropdownMenuItem>
                                                                        <li className='w-40 cursor-pointer rounded-md' onClick={() => {
                                                                            updateTransactions(tran, false);
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <i className="las la-ban text-lg"></i>
                                                                                <p className='text-sm font-serif ml-2'>Make Failed</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        }
                                    </td>
                                </tr>
                            })
                        }
                        {
                            transactions.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                    <div className="text-sm text-red-600 text-center">
                                        No data found.
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table >
                <div className='flex py-2 px-4 items-center bg-blue-50'>
                    <p className='text-sm font-serif'>Show rows per page</p>
                    <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={40}>40</option>
                        <option value={50}>50</option>
                    </select>
                    <div className='flex-grow'></div>
                    <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                    <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                    <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                </div>
            </div >
        </div >
    )
}
