import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, paramAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";

import { toast } from 'react-toastify';
import Moment from 'moment';
import * as XLSX from 'xlsx';
import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import TopBar from '../../core/widgets/ui/TopBar';

import ReviewStars from 'core/widgets/ui/ReviewStars';

import ServiceTicket from '../../services/serviceTickets';

import animPage from '../../assets/anim/anim-pageload.json';
import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

export default function PanelTicket() {

    const [token] = useRecoilState(tokenAtom);
    const [, setParam] = useRecoilState(paramAtom);

    const [tickets, setTickets] = useState([]);

    const [filter, setFilter] = useState({});
    const [ticket, setTicket] = useState({});

    const [tab, setTab] = useState('all');
    const [rtab, setRTab] = useState('a');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [selection, setSelection] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);
    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const ticketService = new ServiceTicket();

    const formVSchema = Yup.object().shape({
        feedback: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            feedback: ''
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                "tid": ticket._id,
                "feedback": values.feedback,
                "type": "admin",
            }
            ticketService.addReview(body, token).then((res) => {
                if (res.status) {
                    setSelection(true);
                    setTicket(res.data);
                } else {
                    setSelection(false);
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                setSubmit(false);
                handleReset();
            });
        }
    })

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "open":
                setFilter({ 'status': 'open' });
                break;
            case "live":
                setFilter({ 'status': 'live' });
                break;
            case "closed":
                setFilter({ 'status': 'closed' });
                break;
            case "hang":
                setFilter({ 'status': 'hang' });
                break;
            default:
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const getTimeDiff = (date1, date2) => {
        const diffInMilliseconds = Math.abs(new Date(date2) - new Date(date1));
        const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
        const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diffInMilliseconds % (1000 * 60)) / 1000);

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }

    const getStsCss = (status) => {
        switch (status) {
            case 'open':
                return " bg-blue-100 text-blue-800";
            case 'live':
                return " bg-yellow-100 text-yellow-800";
            case 'closed':
                return " bg-green-100 text-green-800";
            case 'hang':
                return " bg-red-100 text-red-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const getExportItems = () => {
        let items = [];
        tickets.forEach((item) => {
            items.push({
                ticketId: item.ticketId,
                ticketLang: item.ticketLang,
                ticketMessage: item.ticketMessage,
                ticketPriority: item.ticketPriority,
                waitTime: Moment(item.waitTime).format('DD, MMM yyyy - hh:mm:ss'),
                CallTime: Moment(item.CallTime).format('DD, MMM yyyy - hh:mm:ss'),
                EndTime: Moment(item.EndTime).format('DD, MMM yyyy - hh:mm:ss'),
                type: item.type,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "tickets");
        XLSX.writeFile(workbook, "tickets.xlsx");
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToCsv = () => {
        let headers = ['ticketId,ticketLang,ticketMessage,ticketPriority,waitTime,CallTime,EndTime,type,status,createdAt'];
        let usersCsv = tickets.reduce((acc, item) => {
            const { ticketId, ticketLang, ticketMessage, ticketPriority, waitTime, CallTime, EndTime, type, status, createdAt } = item
            acc.push([ticketId, ticketLang, ticketMessage, ticketPriority, Moment(item.waitTime).format('DD, MMM yyyy - hh:mm:ss'), Moment(item.CallTime).format('DD, MMM yyyy - hh:mm:ss'), Moment(item.EndTime).format('DD, MMM yyyy - hh:mm:ss'), type, status, createdAt].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'tickets.csv',
            fileType: 'text/csv',
        });
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await ticketService.getAllTicket(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setTickets(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await ticketService.getAllTicket(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setTickets(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const getType = (type) => {
        switch (type) {
            case 'partner':
                return 'PA';
            case 'on-demand':
            case 'on-partner':
            case 'helpdesk':
                return 'CU';
            default:
                return "NA";
        }
    }

    const getTicketTitle = (tc) => {
        if (tc.type === "partner") {
            return `(${tc.partnerDetails.subscription.links})`;
        } else {
            return "";
        }
    }

    const getTypeDept = (type) => {
        switch (type) {
            case 'on-demand':
                return "Face-To-Face";
            case 'on-partner':
                return "Tele-Interpreting";
            case 'helpdesk':
                return "Helpdesk";
            default:
                return "NA";
        }
    }

    const setSelTicket = (ticket) => {
        if (ticket.status === "live") {
            setParam({ 'eid': ticket._id });
            navigate(`/home/ticketLive`);
        } else {
            setSelection(true);
            setTicket(ticket);
        }
    }

    const getChipColorA = (status) => {
        switch (status) {
            case 'open':
                return " bg-blue-100 text-blue-800";
            case 'live':
                return " bg-yellow-100 text-yellow-800";
            case 'closed':
                return " bg-green-100 text-green-800";
            case 'hang':
                return " bg-red-100 text-red-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const gotoLink = (link) => {
        window.open(`https://partner.ishcallsign.com/${link}`, '_blank', 'noopener,noreferrer');
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, filter]);

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    return (
        <>
            {
                selection && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                {
                                    isProcessing && <>
                                        <div className='flex items-center justify-between text-2xl'>
                                            <h1 className='font-sans font-medium text-gray-900 uppercase'>Ticket #{ticket.ticketId}</h1>
                                            <span onClick={() => {
                                                setSelection(false);
                                                setTicket({});
                                                setRTab('a');
                                                loadData();
                                                handleReset();
                                            }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                        </div>
                                        <div className='flex mt-4'>
                                            <p className='font-sans font-medium'>Status:
                                                <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(ticket.status)}`}>
                                                    {ticket.status}
                                                </span>
                                            </p>
                                            <p className='ml-8 font-sans font-medium'>Language:
                                                <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-blue-100 text-blue-800`}>
                                                    {ticket.ticketLang}
                                                </span>
                                            </p>
                                        </div>
                                        <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                            <span className='flex items-center'>
                                                <i className="las la-calendar mr-2 text-lg"></i>Ticket Summery
                                            </span>
                                        </div>
                                        <div className='mt-4 mr-8'>
                                            <h3 className='text-sm font-medium'>Ticket Description</h3>
                                            <p className='text-xs font-medium text-gray-400'>{ticket.ticketMessage}</p>
                                        </div>
                                        <div className='w-full h-[1px] bg-gray-900 mt-4'></div>
                                        <div className='mt-4 flex justify-between mr-8'>
                                            <span className='text-sm'>Ticket Type</span>
                                            <span className='text-sm font-medium'>{getType(ticket.type)}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Ticket Priority</span>
                                            <span className='text-sm font-medium'>{ticket.ticketPriority}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Ticket Created Time</span>
                                            <span className='text-sm font-medium'>{Moment(ticket.waitTime).format('DD, MMM yyyy - hh:mm:ss')}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Pick-up Time</span>
                                            <span className='text-sm font-medium'>{(ticket.status === 'open' || ticket.status === 'hang') ? "NA" : Moment(ticket.CallTime).format('DD, MMM yyyy - hh:mm:ss')}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Hang-Down Time</span>
                                            <span className='text-sm font-medium'>{(ticket.status === 'open' || ticket.status === 'live') ? "NA" : Moment(ticket.EndTime).format('DD, MMM yyyy - hh:mm:ss')}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Waiting Time</span>
                                            <span className='text-sm font-medium'>{ticket.status === 'open' ? "NA" : ticket.status === 'hang' ? getTimeDiff(ticket.waitTime, ticket.EndTime) : getTimeDiff(ticket.waitTime, ticket.CallTime)}</span>
                                        </div>
                                        <div className='mt-2 flex justify-between mr-8'>
                                            <span className='text-sm'>Total Talktime</span>
                                            <span className='text-sm font-medium'>{(ticket.status === 'open' || ticket.status === 'hang' || ticket.status === 'live') ? "00:00:00" : getTimeDiff(ticket.CallTime, ticket.EndTime)}</span>
                                        </div>
                                        <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                            <span className='flex items-center'>
                                                <i className="las la-user-tie mr-2 text-lg"></i>Attendees Details
                                            </span>
                                        </div>
                                        {
                                            ticket.attendeesData.map((att) => {
                                                return <div className='mt-4 mr-8'>
                                                    <h3 className='text-sm font-medium uppercase'>{att.type}</h3>
                                                    <p className='text-xs font-medium text-gray-400'>{att.name}</p>
                                                </div>
                                            })
                                        }
                                        {
                                            ticket.type === "partner" && <>
                                                <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                                    <span className='flex items-center'>
                                                        <i className="las la-building mr-2 text-lg"></i>Partner Details
                                                    </span>
                                                </div>
                                                <div className='mt-4 mr-8'>
                                                    <h3 className='text-sm font-medium uppercase'>Company Name</h3>
                                                    <p className='text-xs font-medium text-gray-400'>{ticket.partnerDetails.companyName}</p>
                                                </div>
                                                <div className='mt-4 mr-8'>
                                                    <h3 className='text-sm font-medium uppercase'>Company Address</h3>
                                                    <p className='text-xs font-medium text-gray-400'>{ticket.partnerDetails.companyAddr.address}, {ticket.partnerDetails.companyAddr.city}, {ticket.partnerDetails.companyAddr.state}, {ticket.partnerDetails.companyAddr.country} - {ticket.partnerDetails.companyAddr.zipcode}</p>
                                                </div>
                                                <div className='mt-4 mr-8'>
                                                    <h3 className='text-sm font-medium uppercase'>Company Department</h3>
                                                    <p className='text-xs font-medium text-gray-400'>{ticket.dept.title}</p>
                                                </div>
                                                <div className='mt-4 mr-8'>
                                                    <h3 className='text-sm font-medium uppercase'>Partner Link</h3>
                                                    <p className='text-xs font-medium text-blue-400 cursor-pointer' onClick={() => { gotoLink(ticket.partnerDetails.subscription.links) }}>{ticket.partnerDetails.subscription.links}</p>
                                                </div>
                                            </>
                                        }
                                        <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                            <span className='flex items-center'>
                                                <i className="las la-star-half-alt mr-2 text-lg"></i>Reviews
                                            </span>
                                        </div>
                                        <div class="w-full mt-2">
                                            <div className="py-2 mt-2">
                                                <ul className="flex text-sm font-sans text-gray-600 border-b">
                                                    <li className="mx-2"><button className={`py-2 px-2 ${rtab === 'a' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setRTab('a') }}>Customer</button></li>
                                                    <li className="mx-2"><button className={`py-2 px-2 ${rtab === 'b' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setRTab('b') }}>Interpreter</button></li>
                                                    <li className="mx-2"><button className={`py-2 px-2 ${rtab === 'c' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setRTab('c') }}>Partner</button></li>
                                                    <li className="mx-2"><button className={`py-2 px-2 ${rtab === 'd' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setRTab('d') }}>Admin</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            (() => {
                                                switch (rtab) {
                                                    case 'a':
                                                        return <>
                                                            {
                                                                ticket.customerRating ? <div className='my-2 px-2'>
                                                                    <h3 className='text-base font-medium'>{ticket.customerRating.name}</h3>
                                                                    <ReviewStars rating={ticket.customerRating.rating} />
                                                                    <p className='text-sm italic text-gray-600 mt-2'>{ticket.customerRating.message.length !== 0 ? ticket.customerRating.message : "NA"}</p>
                                                                </div> : <div className='my-2 px-2 h-[100px] flex items-center justify-center'>
                                                                    <p className='text-prime font-medium text-sm'>No review found.</p>
                                                                </div>
                                                            }
                                                        </>
                                                    case 'b':
                                                        return <>
                                                            {
                                                                ticket.interpreterRating ? <div className='my-2 px-2'>
                                                                    <h3 className='text-base font-medium'>{ticket.interpreterRating.name}</h3>
                                                                    <ReviewStars rating={ticket.interpreterRating.rating} />
                                                                    <p className='text-sm italic text-gray-600 mt-2'>{ticket.interpreterRating.message.length !== 0 ? ticket.interpreterRating.message : "NA"}</p>
                                                                </div> : <div className='my-2 px-2 h-[100px] flex items-center justify-center'>
                                                                    <p className='text-prime font-medium text-sm'>No review found.</p>
                                                                </div>
                                                            }
                                                        </>
                                                    case 'c':
                                                        return <>
                                                            {
                                                                ticket.partnerRating ? <div className='my-2 px-2'>
                                                                    <h3 className='text-base font-medium'>{ticket.partnerRating.name}</h3>
                                                                    <p className='text-sm italic text-gray-600 mt-1'>{ticket.partnerRating.message.length !== 0 ? ticket.partnerRating.message : "NA"}</p>
                                                                </div> : <div className='my-2 px-2 h-[100px] flex items-center justify-center'>
                                                                    <p className='text-prime font-medium text-sm'>No review found.</p>
                                                                </div>
                                                            }
                                                        </>
                                                    case 'd':
                                                        return <>
                                                            {
                                                                ticket.adminRating ? <div className='my-2 px-2'>
                                                                    <h3 className='text-base font-medium'>Admin</h3>
                                                                    <p className='text-sm italic text-gray-600 mt-2'>{ticket.adminRating.message.length !== 0 ? ticket.adminRating.message : "NA"}</p>
                                                                </div> : <div className='my-2 px-2 h-[100px] flex items-center justify-center'>
                                                                    <Dialog>
                                                                        <DialogTrigger asChild>
                                                                            <button className='bg-blue-200 text-prime font-medium text-sm px-4 py-1 rounded-md hover:bg-blue-300 hover:text-white'>Add Review</button>
                                                                        </DialogTrigger>
                                                                        <DialogContent className="sm:max-w-md">
                                                                            <DialogHeader>
                                                                                <DialogTitle>Add Feedback</DialogTitle>
                                                                            </DialogHeader>
                                                                            <hr />
                                                                            <form onSubmit={handleSubmit}>
                                                                                <div className="w-full">
                                                                                    <div className="">
                                                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Feedback<sup className="text-red-600">*</sup></label>
                                                                                        <textarea id="feedback" value={values.feedback} onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-20 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Your feedback"></textarea>
                                                                                        {(errors.feedback && touched.feedback) && <p className='text-xs text-red-400 mt-1'>{errors.feedback}</p>}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="px-2 py-3 text-right">
                                                                                    <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                                                                                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                                                        {!submit && "SUBMIT"}
                                                                                    </button>
                                                                                </div>
                                                                            </form>
                                                                        </DialogContent>
                                                                    </Dialog>
                                                                </div>
                                                            }
                                                        </>
                                                    default:
                                                        return <div className='my-2 px-2 h-[100px] flex items-center justify-center'>
                                                            <p className='text-prime font-medium text-sm'>No review found.</p>
                                                        </div>
                                                }
                                            })()
                                        }
                                    </>
                                }
                                {
                                    !isProcessing && <>
                                        <div className='flex items-center justify-center w-full h-full'>
                                            <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                                        </div>
                                    </>
                                }
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            <ScrollArea className='h-screen w-full'>
                <div className="w-full h-full py-3 px-3">
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                                <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                    Ticket Master
                                </h2>
                                <TopBar />
                            </div>
                            <div class="w-full flex justify-between items-center">
                                <div class="w-full">
                                    <div className="p-2 mt-2">
                                        <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'open' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('open') }}>Open</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'live' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('live') }}>Live</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'closed' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('closed') }}>Closed</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'hang' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('hang') }}>Hang-Up</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 mt-4 px-2">
                        <div className="w-full shadow-md rounded-md border">
                            <table className="min-w-full divide-y divide-gray-200 px-4">
                                <thead className="bg-gray-50">
                                    <tr className=''>
                                        <th scope="col" className="border-b border-gray-200" colSpan={11}>
                                            <div className='w-full grid grid-cols-12 gap-2'>
                                                <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                    <div className='w-full flex col-span-12 items-center'>
                                                        <div className='w-[80px]'>
                                                            <p>Search :</p>
                                                        </div>
                                                        <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by ticket" className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                    </div>
                                                </form>
                                                <div className='col-span-1 flex items-center'>
                                                    <DropdownMenu>
                                                        <DropdownMenuTrigger>
                                                            <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                        </DropdownMenuTrigger>
                                                        <DropdownMenuContent>
                                                            <div
                                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                                <div>
                                                                    <ul className='flex flex-col items-start p-2'>
                                                                        <DropdownMenuItem>
                                                                            <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                exportToExcel();
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                </div>
                                                                            </li>
                                                                        </DropdownMenuItem>
                                                                        <DropdownMenuItem>
                                                                            <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                                exportToCsv();
                                                                            }}>
                                                                                <div className='flex items-center text-base'>
                                                                                    <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                </div>
                                                                            </li>
                                                                        </DropdownMenuItem>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </DropdownMenuContent>
                                                    </DropdownMenu>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            TICKET DETAILS
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Ticket Type
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Department
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Priority Type
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Ticket Created Time
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Waiting Time
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Pick-up Time
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Hang-Down Time
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Total Talktime
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Ratings
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        tickets.length !== 0 && tickets.map((ticket) => {
                                            return <tr className="hover:bg-gray-100" key={ticket._id}>
                                                <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => {
                                                    setSelTicket(ticket);
                                                }}>
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div className='flex items-center text-prime uppercase'>
                                                            {ticket.ticketId} {`${getTicketTitle(ticket)}`}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{getType(ticket.type)}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {ticket.dept != null && Object.keys(ticket.dept).length !== 0 && <div>{ticket.dept.title}</div>}
                                                        {ticket.dept != null && Object.keys(ticket.dept).length === 0 && <div>NA</div>}
                                                        {ticket.dept == null && <div>{getTypeDept(ticket.type)}</div>}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{ticket.ticketPriority}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{Moment(ticket.waitTime).format('DD, MMM yyyy - hh:mm:ss')}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{ticket.status === 'open' ? "NA" : ticket.status === 'hang' ? getTimeDiff(ticket.waitTime, ticket.EndTime) : getTimeDiff(ticket.waitTime, ticket.CallTime)}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{(ticket.status === 'open' || ticket.status === 'hang') ? "NA" : Moment(ticket.CallTime).format('DD, MMM yyyy - hh:mm:ss')}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{(ticket.status === 'open' || ticket.status === 'live') ? "NA" : Moment(ticket.EndTime).format('DD, MMM yyyy - hh:mm:ss')}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        <div>{(ticket.status === 'open' || ticket.status === 'hang' || ticket.status === 'live') ? "00:00:00" : getTimeDiff(ticket.CallTime, ticket.EndTime)}</div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {ticket.customerRating && <ReviewStars rating={ticket.customerRating.rating} />}
                                                    {!ticket.customerRating && <ReviewStars rating={0} />}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getStsCss(ticket.status)}`}>
                                                        {ticket.status.toUpperCase()}
                                                    </span>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    {
                                        tickets.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap" colSpan="11">
                                                <div className="text-sm text-red-600 text-center">
                                                    No data found.
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table >
                            <div className='flex py-2 px-4 items-center bg-blue-50'>
                                <p className='text-sm font-serif'>Show rows per page</p>
                                <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={40}>40</option>
                                    <option value={50}>50</option>
                                </select>
                                <div className='flex-grow'></div>
                                <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                                <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                                <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            </div>
                        </div >
                    </div >
                </div>
            </ScrollArea >
        </>
    )
}
